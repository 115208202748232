<template>
  <td
    v-if="item.subOrderLoading"
    :colspan="headers.length"
  >
    <v-row justify="center">
      <v-col cols="auto">
        <v-progress-circular
          class="mx-auto"
          color="primary"
          size="30"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </td>
  <td
    v-else-if="item.subOrder"
    class="white"
    :colspan="headers.length"
  >
    <v-container fluid>
      <v-sheet outlined class="pa-2 rounded">
        <table-shipper-info
          :item="item"
          @fetchOrders="$emit('fetchOrders')"
          @isHasWaitingOrder="$emit('isHasWaitingOrder')"
          @navigateToPageUpdateOrders="(shipment) => $emit('navigateToPageUpdateOrders', item, shipment)"
        />
        <table-trip-info :item="item"/>
        <btn-update-route
          v-if="showBtnUpdateRoute"
          class="mt-4"
          :status="status"
          :item="item"
          @fetchSubOrder="$emit('fetchSubOrder', item)"
        />
        <table-vehicle-info
          :item="item"
          @fetchOrders="$emit('fetchOrders')"
          @fetchSubOrder="$emit('fetchSubOrder', item)"
          @fetchNewLegend="$emit('fetchNewLegend', item)"
          @isHasWaitingOrder="$emit('isHasWaitingOrder')"
        />
        <table-multimoda-info
          :item="item"
          @fetchSubOrder="$emit('fetchSubOrder', item)"
          @fetchNewLegend="$emit('fetchNewLegend', item)"
          @isHasWaitingOrder="$emit('isHasWaitingOrder')"
        />
        <table-qty-info
          v-if="status === 'finished' || status === 'process'"
          :item="item"
          @fetchSubOrder="$emit('fetchSubOrder', item)"
          @viewDetailOrder="$emit('viewDetailOrder', item)"
        />
      </v-sheet>
    </v-container>
  </td>
</template>

<script>
import TableShipperInfo from './TableShipperInfo.vue';
import TableTripInfo from './TableTripInfo.vue';
import TableVehicleInfo from './TableVehicleInfo.vue';
import TableMultimodaInfo from './TableMultimodaInfo.vue';
import TableQtyInfo from './TableQtyInfo.vue';
import BtnUpdateRoute from './BtnUpdateRoute.vue';

export default {
  components: {
    TableShipperInfo,
    TableTripInfo,
    TableVehicleInfo,
    TableMultimodaInfo,
    TableQtyInfo,
    BtnUpdateRoute,
  },
  props: {
    status: {
      type: String,
      default: () => '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    showBtnUpdateRoute() {
      const showInPage = ['unconfirmed', 'process'];
      return showInPage.includes(this.status);
    },
  },
};
</script>
