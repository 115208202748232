<template>
  <section>
    <v-row>
      <v-col>
        <h4 class="pl-2">{{$_strings.order.QUANTITY_INFO}}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-1">
        <v-data-table
          :headers="displayedHeaders"
          calculate-widths
          hide-default-footer
          :items="items"
          class="grey lighten-4"
          :items-per-page="100"
        >
          <template v-slot:[`item.calculation`]={item}>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="grey"
                  small
                  icon
                  :disabled="disableBtnCalculate(item)"
                  @click="showDialogChangeQty(item)"
                >
                  <v-icon>
                    mdi-autorenew
                  </v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.order.CALCULATE_QTY}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.action`]={item}>
            <v-btn
              v-if="showBtnCalculationProcess(item)"
              @click="calculationProcess"
              small
              color="primary"
            >
              {{$_strings.order.CALCULATION_PROCESS}}
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogChangeQuantity
      ref='dialogChangeQuantity'
      @handleAfterChangeQty="handleAfterChangeQty"
      :isDelivery="$route.params.status === 'process'"
    />
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '../../../helper/commonHelpers';
import RenderFunction from './function';
import DialogChangeQuantity from '../Dialog/ChangeQuantity.vue';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogChangeQuantity,
  },
  computed: {
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
    displayedHeaders() {
      const { status } = this.$route.params;
      const renderFunction = new RenderFunction(this);
      return renderFunction.tableHeader('qty').filter((t) => {
        if (status === 'process' && t.value === 'action') return;
        return t;
      });
    },
    items() {
      const { index, shipmentNumber, serviceType } = this.item;
      return this.item.subOrder.shipmentResponse.map((i) => (
        {
          id: i.id,
          indexShipment: index,
          shipmentNumber,
          orderNumber: i.orderNumber,
          cubic: i.cubic,
          tonnage: i.tonnage,
          carton: i.carton,
          isCalculated: i.isCalculated,
          orderStatus: i.orderStatus,
          serviceType,
        }
      ));
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    showBtnCalculationProcess(item) {
      const { roleName = '' } = this.myUserInfo;
      const rolesNamesAreCannotCalculate = ['DATA_ENTRY'];
      const { canUpdate } = this.userAccess;
      const status = ['terkonfirmasi', 'kedaluwarsa', 'dibatalkan', 'menunggu konfirmasi', 'menunggu konfirmasi transporter', 'menunggu konfirmasi driver'];
      if (rolesNamesAreCannotCalculate.includes(roleName.toUpperCase())) return false;
      if (canUpdate && !item.isCalculated && !status.includes(item.orderStatus.toLowerCase())) return true;
      return false;
    },
    disableBtnCalculate(item) {
      const isCalculated = typeof item.carton === 'number' || typeof item.cubic === 'number' || typeof item.tonnage === 'number';
      const { canUpdate } = this.userAccess;
      const status = [
        'pesanan dibuat',
        'terkonfirmasi',
        'kedaluwarsa',
        'dibatalkan',
        'menunggu konfirmasi',
        'menunggu konfirmasi transporter',
        'menunggu konfirmasi driver',
      ];
      if (!canUpdate
        || status.includes(item.orderStatus.toLowerCase())
        || item.isCalculated
        || isCalculated
      ) return true;
      return false;
    },
    showDialogChangeQty(item) {
      this.$refs.dialogChangeQuantity.orderSelected = item;
      this.$refs.dialogChangeQuantity.dialog = true;
    },
    handleAfterChangeQty() {
      this.$delete(this.item, 'subOrder');
      this.$emit('fetchSubOrder', this.item);
    },
    calculationProcess() {
      this.$emit('viewDetailOrder', this.item);
    },
  },
};
</script>
