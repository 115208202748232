var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isShowGroupBtn('btn-1'))?_c('section',[(_vm.item.isLoadingNewLegend)?_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"color":"primary","size":"25","indeterminate":""}}):(_vm.item.warning && _vm.item.warning.toLowerCase() === 'yellow')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"yellow accent-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-outline ")])]}}],null,false,752651657)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.PARTIALLY_ASSIGNED))])]):(_vm.item.warning && _vm.item.warning.toLowerCase() === 'red')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,2514728025)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.HAVE_NOT_BEEN_ASSIGNED))])]):_vm._e(),(_vm.item.canCombine && _vm.userAccess.canUpdate)?_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setDialogCombineLcl($event)}}},[_vm._v(" "+_vm._s(_vm.$_strings.order.MERGE)+" ")]):_vm._e()],1):_vm._e(),(_vm.isShowGroupBtn('btn-2'))?_c('section',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.userAccess.canUpdate)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","disabled":_vm.item.latestStatus === 'MENUNGGU_KONFIRMASI_DRIVER'},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showDialogConfirmation({confirm: false})}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle-outline ")]):_vm._e()]}}],null,false,3878697872)},[_c('span',[_vm._v("Tolak")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.userAccess.canUpdate)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleAcceptButton($event)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle-outline ")]):_vm._e()]}}],null,false,3535528548)},[_c('span',[_vm._v("Terima")])])],1):_vm._e(),_c('DialogCombineLcl',{ref:"dialogCombineLcl",on:{"fetchOrders":function($event){return _vm.$emit('fetchOrders')}}}),_c('DialogCancelOrder',{ref:"dialogCancelOrder",on:{"cancelOrder":_vm.cancelOrder}}),_c('DialogVehicle',{ref:"dialogVehicle",on:{"fetchOrders":function($event){return _vm.$emit('fetchOrders')},"refreshDataOnPageUnconfirmed":function($event){return _vm.$emit('refreshDataOnPage', 'unconfirmed');}}}),_c('DialogConfirmation',{ref:"dialogConfirmation",on:{"fetchOrders":function($event){return _vm.$emit('fetchOrders')},"refreshDataOnPageUnconfirmed":function($event){return _vm.$emit('refreshDataOnPage', 'unconfirmed');}}}),_c('DialogConfirmationDriver',{ref:"dialogConfirmationDriver",on:{"fetchOrders":function($event){return _vm.$emit('fetchOrders')},"refreshDataOnPageUnconfirmed":function($event){return _vm.$emit('refreshDataOnPage', 'unconfirmed');}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }