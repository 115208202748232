<template>
  <section>
    <v-row>
      <v-col><h4 class="pl-2">{{$_strings.order.VEHICLE_INFO}}</h4></v-col>
    </v-row>
    <v-row>
      <v-col class="pa-1">
        <v-data-table
          :headers="displayedHeaders"
          calculate-widths
          hide-default-footer
          :items="items"
          class="grey lighten-4"
          :items-per-page="100"
        >
          <template v-slot:[`item.pickupDate`]={item}>
            <p class="ma-0">{{dateFormat(item.pickupDate)}}</p>
            <p>{{timeFormat(item.pickupDate)}}</p>
          </template>
          <template v-slot:[`item.estimateArrival`]={item}>
            <p class="ma-0">{{item.estimateArrival ? dateFormat(item.estimateArrival) : '-'}}</p>
            <p v-if="item.estimateArrival">{{timeFormat(item.estimateArrival)}}</p>
          </template>
          <template v-slot:[`item.status`]={item}>
            <p
              class="ma-0 pa-0"
              :class="getColorStatus(item.status)">
              {{item.status}}
            </p>
          </template>
          <template v-slot:[`item.action`]={item}>
            <v-btn
              color="primary"
              small
              icon
              :disabled="!statusAllowedToAssign3pl.includes(item.status.toLowerCase())"
              @click="showDialogAssign3PL(item)"
            >
              <v-icon>
                mdi-circle-edit-outline
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogAssign3PL
      @showDialogDriverAndTransport="showDialogDriverAndTransport"
      @handleAfterAssign3PL="handleAfterAssign3PL"
      ref="dialogAssign3PL"
    />
    <DialogAssign3PLLcl
      @showDialogDriverAndTransport="showDialogDriverAndTransport"
      @handleAfterAssign3PL="handleAfterAssign3PL"
      ref="dialogAssign3PLLcl"
    />
    <DialogVehicle
      ref="dialogVehicle"
      @fetchOrders="$emit('fetchOrders')"
      @refreshDataOnPageUnconfirmed="() => {}"
    />
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '../../../helper/commonHelpers';
import RenderFunction from './function';
import DialogAssign3PL from '../Dialog/Assign3PL.vue';
import DialogAssign3PLLcl from '../Dialog/Assign3plLcl';
import DialogVehicle from '../Dialog/Vehicle';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogAssign3PL,
    DialogAssign3PLLcl,
    DialogVehicle,
  },
  data() {
    return {
      statusAllowedToAssign3pl: [
        'waktu habis',
        'pesanan dibuat',
        'ditolak',
      ],
      isShowDialogVehicle: false,
      itemSelected: {},
    };
  },
  computed: {
    showBtnAction() {
      const showButtonInPage = ['unconfirmed', 'process'];
      const { status } = this.$route.params;
      const { canUpdate } = this.userAccess;
      return canUpdate && showButtonInPage.includes(status);
    },
    displayedHeaders() {
      const renderFunction = new RenderFunction(this);
      return renderFunction.tableHeader('vehicle').filter((header) => {
        if (header.value === 'action' && !this.showBtnAction) return;
        return header;
      });
    },
    items() {
      const routeItems = [];
      this.item.subOrder.routes.forEach((route) => {
        // CHECK DUPLICATE CARGO NUMBER OR PUSH IF CARGO NUMBER IS NULL AND ROUTE TYPE IS NOT AIR
        if (
          (
            !routeItems.find((findRoute) => findRoute.cargoNumber === route.cargoNumber)
            || !route.cargoNumber
          )
          && route.routeType === 'LAND'
        ) {
          return routeItems.push(route);
        }
      });
      return routeItems;
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    getColorStatus(status) {
      const _status = status.toLowerCase();
      const redStatus = ['dibatalkan', 'ditolak', 'kedaluwarsa', 'dokumen tidak lengkap'];
      const greenStatus = ['barang diterima utuh', 'barang diterima tidak utuh'];
      if (redStatus.includes(_status)) return 'red--text';
      if (greenStatus.includes(_status)) return 'green--text';
      return 'black--text';
    },
    showDialogVehicle() {
      const { shipmentsCargoId } = this.itemSelected;
      this.$refs.dialogVehicle.shipment = this.itemSelected;
      this.$refs.dialogVehicle.isAutoAssign = true;
      this.$refs.dialogVehicle.dialog = true;
      this.$refs.dialogVehicle.form.shipmentCargoId = shipmentsCargoId;
    },
    showDialogAssign3PL(item) {
      const { routeType, isManualConfirmed } = item;
      const isAutoAssign = routeType === 'LAND' && isManualConfirmed;
      this.itemSelected = item;
      const itemsCargo = this.item.subOrder.routes.map((x) => ({
        cargoNumber: x.cargoNumber,
      })).filter((x) => x.cargoNumber);
      if (item.serviceType === 'LCL') {
        this.$refs.dialogAssign3PLLcl.itemsCargo = itemsCargo;
        this.$refs.dialogAssign3PLLcl.shipment = item;
        this.$refs.dialogAssign3PLLcl.isAutoAssign = isAutoAssign;
        this.$refs.dialogAssign3PLLcl.dialog = true;
        return;
      }
      this.$refs.dialogAssign3PL.shipment = item;
      this.$refs.dialogAssign3PL.itemsCargo = itemsCargo;
      this.$refs.dialogAssign3PL.isAutoAssign = isAutoAssign;
      this.$refs.dialogAssign3PL.dialog = true;
    },
    showDialogDriverAndTransport(form) {
      this.itemSelected = {
        ...this.itemSelected,
        ...form,
      };
      this.showDialogVehicle();
    },
    handleAfterAssign3PL() {
      const { status } = this.$route.params;
      this.$delete(this.item, 'subOrder');
      this.$emit('fetchSubOrder', this.item);
      if (status === 'unconfirmed') {
        this.$emit('fetchNewLegend', this.item);
        this.$emit('isHasWaitingOrder');
      }
    },
  },
};
</script>
