<template>
  <div>
    <section v-if="isShowGroupBtn('btn-1')">
      <v-progress-circular
        class="mx-auto"
        color="primary"
        size="25"
        indeterminate
        v-if="item.isLoadingNewLegend"
      ></v-progress-circular>
      <v-tooltip v-else-if="item.warning && item.warning.toLowerCase() === 'yellow'" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="yellow accent-4"
            v-bind="attrs"
            v-on="on"
          >
            mdi-alert-outline
          </v-icon>
        </template>
        <span>{{$_strings.order.PARTIALLY_ASSIGNED}}</span>
      </v-tooltip>
      <v-tooltip v-else-if="item.warning && item.warning.toLowerCase() === 'red'" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="red"
            v-bind="attrs"
            v-on="on"
          >
            mdi-help-circle-outline
          </v-icon>
        </template>
        <span>{{$_strings.order.HAVE_NOT_BEEN_ASSIGNED}}</span>
      </v-tooltip>
      <v-btn
        v-if="item.canCombine && userAccess.canUpdate"
        color="primary"
        class="mr-2 ml-2"
        small
        @click.stop.prevent="setDialogCombineLcl"
      >
        {{$_strings.order.MERGE}}
      </v-btn>
    </section>
    <section v-if="isShowGroupBtn('btn-2')">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="userAccess.canUpdate"
            v-bind="attrs"
            v-on="on"
            color="red"
            :disabled="item.latestStatus === 'MENUNGGU_KONFIRMASI_DRIVER'"
            @click.stop.prevent="showDialogConfirmation({confirm: false})"
          >
            mdi-close-circle-outline
          </v-icon>
        </template>
        <span>Tolak</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="userAccess.canUpdate"
            v-bind="attrs"
            v-on="on"
            color="primary"
            @click.stop.prevent="handleAcceptButton"
          >
            mdi-check-circle-outline
          </v-icon>
        </template>
        <span>Terima</span>
      </v-tooltip>
    </section>
    <DialogCombineLcl
      ref="dialogCombineLcl"
      @fetchOrders="$emit('fetchOrders')"
    />
    <DialogCancelOrder
      ref="dialogCancelOrder"
      @cancelOrder="cancelOrder"
    />
    <DialogVehicle
      ref="dialogVehicle"
      @fetchOrders="$emit('fetchOrders')"
      @refreshDataOnPageUnconfirmed="$emit('refreshDataOnPage', 'unconfirmed');"
    />
    <DialogConfirmation
      ref="dialogConfirmation"
      @fetchOrders="$emit('fetchOrders')"
      @refreshDataOnPageUnconfirmed="$emit('refreshDataOnPage', 'unconfirmed');"
    />
    <DialogConfirmationDriver
      ref="dialogConfirmationDriver"
      @fetchOrders="$emit('fetchOrders')"
      @refreshDataOnPageUnconfirmed="$emit('refreshDataOnPage', 'unconfirmed');"
    />
  </div>
</template>
<script>
import { getDateTimeTz } from '@/helper/commonHelpers';
import DialogConfirmation from '../Dialog/Confirmation.vue';
import DialogCancelOrder from '../Dialog/CancelOrder.vue';
import DialogVehicle from '../Dialog/Vehicle';
import DialogConfirmationDriver from '../Dialog/ConfirmationDriver.vue';
import DialogCombineLcl from '../Dialog/CombineOrderLcl';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: '',
    },
  },
  components: {
    DialogCancelOrder,
    DialogVehicle,
    DialogConfirmation,
    DialogConfirmationDriver,
    DialogCombineLcl,
  },
  methods: {
    getDateTimeTz,
    setDialogCombineLcl() {
      this.$refs.dialogCombineLcl.shipment = this.item;
      this.$refs.dialogCombineLcl.dialog = true;
    },
    showDialog(ref) {
      this.$refs[ref].shipment = this.item;
      this.$refs[ref].dialog = true;
      this.$refs[ref].form.shipmentCargoId = this.item.id;
    },
    handleAcceptButton() {
      if (this.item.latestStatus === 'MENUNGGU_KONFIRMASI_TRANSPORTER') return this.showDialog('dialogVehicle');
      if (this.item.latestStatus === 'MENUNGGU_KONFIRMASI_DRIVER') {
        this.showDialog('dialogConfirmationDriver');
      }
    },
    showDialogConfirmation({ confirm }) {
      this.$refs.dialogConfirmation.confirmationType = 'transporter'; // transporter / driver
      this.$refs.dialogConfirmation.form.confirm = confirm;
      this.$refs.dialogConfirmation.order = this.item;
      this.$refs.dialogConfirmation.dialog = true;
    },
    isShowGroupBtn(typeBtn) {
      const showGroupBtn1InPage = ['unconfirmed'];
      const showGroupBtn2InPage = ['confirmation_transporter', 'confirmation_driver'];
      switch (typeBtn) {
        case 'btn-1': return showGroupBtn1InPage.includes(this.status);
        case 'btn-2': return showGroupBtn2InPage.includes(this.status);
        default: return false;
      }
    },
    async cancelOrder(id, form) {
      try {
        this.$refs.dialogCancelOrder.isLoading = true;
        await this.$_services.order.cancelShipment(id, form);
        this.$dialog.notify.success('Berhasil membatalkan pesanan');
        this.$refs.dialogCancelOrder.dialog = false;
        this.$emit('fetchOrders');
      } finally {
        this.$refs.dialogCancelOrder.isLoading = false;
      }
    },
  },
};
</script>
